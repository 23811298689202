export class BeerSearchDto {
  text = null;
  sort = 'createDate';
  sortDirection = 'desc';
}

export class BeerSearchRequest {
  limit = 30;
  skip = 0;
  count = 0;
  showingCount = 0;
  search = new BeerSearchDto();
}
