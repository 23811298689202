import React from 'react';
import './NotebookEditForm.scss';

import useApi from '../../../../core/useApi';
import { CxSelect } from '../../../../core/components';

// Mui
import { useSnackbar } from 'notistack';

// Forms
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// Mui
import TextField from '@mui/material/TextField';

const NotebookEditForm = ({ lang, notebook, notebooks, formEventHandler, contentGroupId }) => {
  const { post, put } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const onSaveClick = async (data) => {
    if (data.id === '') {
      await post('notebook', data);
    } else {
      await put(`notebook/${data.id}`, data);
    }

    reset();
    enqueueSnackbar('Notebook saved!', {
      variant: 'success',
    });

    formEventHandler('saved');
  };

  const onCancelClick = () => {
    reset();
    formEventHandler('canceled');
  };

  // Form
  const schema = yup
    .object({
      name: yup.string().max(64, 'Maximum length is 64 characters').required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      id: notebook.id,
      name: notebook.name,
      parentId: notebook.parentId,
      sharedWithMe: notebook.sharedWithMe,
      contentGroupId: contentGroupId,
    },
  });

  // Group Select
  const [parentId, setParentId] = React.useState(notebook.parentId == null ? '' : notebook.parentId);

  const notebookList = () => {
    const nb = notebooks.filter((x) => x.id !== notebook.id).map(({ id, name }) => ({ name: name, value: id }));
    nb.unshift({ value: '', name: '- No Parent -' });
    return nb;
  };

  const handleGroupWithChange = (value) => {
    setParentId(value);
    setValue('parentId', value, true);
  };

  return (
    <div className="cx-notebooks-form">
      <section>
        <form onSubmit={handleSubmit(onSaveClick)}>
          <input type="hidden" name="id" {...register('id')} />
          <input type="hidden" name="contentGroupId" {...register('contentGroupId')} />
          <input type="hidden" name="sharedWithMe" {...register('sharedWithMe')} />
          <div>
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  {...field}
                  id="name"
                  label={lang.name}
                  variant="outlined"
                  fullWidth
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : ''}
                />
              )}
              rules={{ required: true, maxLength: 50 }}
            />
          </div>

          {notebookList().length > 0 && !notebook.sharedWithMe ? (
            <div>
              <CxSelect
                name="parentId"
                label={lang.groupWith}
                fullWidth
                items={notebookList()}
                defaultValue={parentId}
                onChange={handleGroupWithChange}
              />
            </div>
          ) : null}

          <div className="cx-admin-footer-actions">
            <button type="button" className="cx-button cx-button-cancel" tabIndex="-1" onClick={onCancelClick}>
              {lang.cancel}
            </button>
            <button type="submit" className="cx-button cx-button-save">
              {lang.save}
            </button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default NotebookEditForm;
