export class BeerStyleSearchDto {
  text = '';
  sort = 'name';
  sortDirection = 'asc';
}

export class BeerStyleSearchRequest {
  limit = 1000;
  skip = 0;
  count = 0;
  search = new BeerStyleSearchDto();
}
