import React, { useState, useEffect } from 'react';
import './ListingSearch.scss';

import { withRouter } from 'react-router-dom';

import ListingListItem from '../list/ListingListItem';

import useApi from '../../../../core/useApi';
import { Loader } from '../../../../core/components';

const ListingSearch = ({ lang, history }) => {
  const { get } = useApi();
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const searchListings = async () => {
    setIsLoading(true);
    try {
      const response = await get('admin/listings');
      setItems(response.items);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    searchListings();
  }, []);

  const onItemSelected = (item) => {
    history.push(`/listings/${item.id}`);
  };
  const onItemDelete = (item) => {
    history.push(`/listings/${item.id}`);
  };

  const listings = items.map((item) => (
    <ListingListItem
      key={item.id}
      lang={lang}
      item={item}
      onSelected={onItemSelected}
      onEdit={onItemSelected}
      onDelete={onItemDelete}
      isAdmin={true}
    />
  ));

  return (
    <>
      <div className="cx-listing-search">
        <div className="cx-listing-search-search">SOME SEARCH HERE</div>
        <div className="cx-listing-search-list">
          {isLoading ? <Loader /> : listings}
          <div className="clearfix"></div>
        </div>
      </div>
    </>
  );
};

export default withRouter(ListingSearch);
