import React, { useEffect, useState } from 'react';
import './Notification.scss';

import PropTypes from 'prop-types';

// State
import { useSelector } from 'react-redux';

// DK
import useApi from '../../../core/useApi';
import { Loader, EmptyState, Breadcrumb, Card } from '../../../core/components';

const Notification = ({ history, match }) => {
  const { get, post } = useApi();
  const lang = useSelector((state) => state.app.lang);
  const breadcrumb = [{ name: lang.notifications, path: null }];
  const [notificationId] = useState(match.params.id);
  const [message, setMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getNotification = async (notificationId) => {
    setIsLoading(true);
    try {
      const response = await get(`notifications/${notificationId}`);
      const msg = JSON.parse(response.notification.message);
      setMessage(msg);
    } catch (error) {
      console.log(error.message);
      setMessage({
        notificationType: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getNotification(notificationId);
  }, []);

  const onActionButtonClick = async () => {
    const response = await post(`core/share/confirm/${notificationId}`);
    switch (response.contentName) {
      case 'Notebook':
        history.push('/notebooks');
        break;
      default:
        history.push('/dashboard');
        break;
    }
  };

  const onCancelClick = () => {
    history.push('/dashboard');
  };

  const renderNotification = (notificationType) => {
    // console.log(notificationType);
    switch (notificationType) {
      case 'Notebook':
        var msg2 = lang.notebookSharedPreNotificationInstructions2;
        msg2 = msg2.replace('#fromName', message.fromName);
        msg2 = msg2.replace('#notificationType', notificationType);
        msg2 = msg2.replace('#contentName', message.content.name);

        var msg3 = lang.notebookSharedPreNotificationInstructions3;

        return (
          <>
            <Card>
              <div>
                <p>{lang.notebookSharedPreNotificationInstructions1.replace('#toName', message.toName)}</p>
                <p>{msg2}</p>
                <p>{msg3}</p>
                <button type="button" className="cx-button cx-button-cancel" onClick={onCancelClick}>
                  {lang.cancel}
                </button>
                <button type="button" className="cx-button cx-button-action" onClick={onActionButtonClick}>
                  {lang.continue}
                </button>
              </div>
            </Card>
          </>
        );
      default:
        return (
          <EmptyState
            title={lang.notificationConfirmationLoadingError}
            actionText={lang.dashboard}
            onActionClick={onCancelClick}
          />
        );
    }
  };

  return (
    <>
      <Breadcrumb items={breadcrumb} />
      <div className="cx-notification">
        {isLoading ? <Loader /> : <>{renderNotification(message.notificationType)}</>}
      </div>
    </>
  );
};

Notification.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default Notification;
