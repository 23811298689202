import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const BrowserWindowsReachBottomScroll = ({ padding, action, children }) => {
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentWindowHeight = window.innerHeight ? window.innerHeight : document.body.clientHeight; // gets window height

      // gets current vertical scrollbar position
      let currentScrollPosition = window.scrollY
        ? window.scrollY
        : document.documentElement.scrollTop
        ? document.documentElement.scrollTop
        : document.body.scrollTop;

      // if scrollbar reaches to bottom
      if (document.body.scrollHeight - padding <= currentScrollPosition + currentWindowHeight) {        
        action();
      }

      // Show or hide the scroll to top button
      if (currentScrollPosition > 200) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    const debounce = (func, wait) => {
      let timeout;
      return function (...args) {
        const later = () => {
          clearTimeout(timeout);
          func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      };
    };

    const debouncedHandleScroll = debounce(handleScroll, 300);

    window.addEventListener('scroll', debouncedHandleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [padding, action]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      {children}
      {showScrollToTop && (
        <button onClick={scrollToTop} style={scrollToTopButtonStyle}>
          ▲
        </button>
      )}
    </>
  );
};

const scrollToTopButtonStyle = {
  position: 'fixed',
  bottom: '16px',
  right: '20px',
  padding: '10px 12px',
  fontSize: '16px',
  backgroundColor: '#007bff',
  color: '#fff',
  border: 'none',
  borderRadius: '50%',
  cursor: 'pointer',
};

BrowserWindowsReachBottomScroll.propTypes = {
  padding: PropTypes.number.isRequired,
  action: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default BrowserWindowsReachBottomScroll;
