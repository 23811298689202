import React, { useEffect, useState, useRef } from 'react';
import './NotebookContainer.scss';

import { withRouter } from 'react-router-dom';

// State
import { connect } from 'react-redux';
import { setWorkingNotebook } from '../../../redux/notes/notes.actions';

// Mui
import { useSnackbar } from 'notistack';

import useApi from '../../../../core/useApi';
import { Breadcrumb, Loader, Modal, Share } from '../../../../core/components';
import randomService from '../../../../core/services/random.service';
import NotesNav from '../NotesNav';
import NotebookEditForm from './NotebookEditForm';
import NotebookItem from './NotebookItem';

const NotebookContainer = ({
  lang,
  history,
  workspaceId,
  contentGroup,
  workingNotebook,
  setWorkingNotebook,
  breakPoint,
}) => {
  const { get, put, del } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const breadcrumb = [
    { name: lang.dashboard, path: '/dashboard' },
    { name: lang.notebooks, path: null },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [notebooks, setNotebooks] = useState([]);
  const [notebook, setNotebook] = useState();
  const [showEditForm, setShowEditForm] = useState(false);
  const [databind, setDatabind] = useState();

  // Screen Size
  const bodyHeightPadding = 132;
  const [noteContainerHeight, setNoteContainerHeight] = useState(window.innerHeight - bodyHeightPadding);

  useEffect(() => {
    const handleResize = () => {
      setNoteContainerHeight(window.innerHeight - bodyHeightPadding);
    };

    window.addEventListener('resize', handleResize);

    // Initial calculation
    handleResize();

    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getNotebooks = async () => {
    const response = await get('notebook');
    setNotebooks(response);

    // Set first notebook as working if none exist.
    if (response.length > 0 && !workingNotebook) {
      const defaultNotebook = response.filter((x) => x.isDefault)[0];
      setWorkingNotebook(defaultNotebook);
    }

    if (response.length === 0) {
      setWorkingNotebook(null);
      setNotebook({
        id: '',
        name: '',
        isDefault: true,
        parentId: '',
        contentGroupId: contentGroup.id,
      });
      setShowEditForm(true);
    }
  };

  useEffect(() => {
    getNotebooks();
  }, [databind]);

  // Notebook Selection
  const onNotebookSelected = (notebookId) => {
    if (notebookId !== workingNotebook.id) {
      handleNotebookSelected(notebookId);
      history.push('/notes');
    }
  };

  const handleNotebookSelected = (id) => {
    if (notebooks.length > 0 && id) {
      // With at least one notebook, grab default/first one by default.
      let defaultNotebookId = notebooks.filter((x) => x.isDefault)[0].id;

      // When selected found, assign as working notebook.
      const selectedNotebook = notebooks.filter((x) => x.id === id);
      if (selectedNotebook) {
        defaultNotebookId = selectedNotebook[0].id;
      }

      if (!workingNotebook || workingNotebook.id !== defaultNotebookId) {
        setWorkingNotebook(selectedNotebook[0]);
      }
    }
  };

  const newNotebookClick = () => {
    setNotebook({
      id: '',
      name: '',
      isDefault: false,
      parentId: '',
      contentGroupId: contentGroup.id,
    });
    setShowEditForm(true);
  };

  const editNotebookClick = (notebook) => {
    setNotebook(notebook);
    setShowEditForm(true);
  };

  // Delete Notebook
  const [deletingNotebookId, setDeletingNotebookId] = useState(null);
  const deleteNotebookConfirmation = useRef();
  const openDeleteNotebookConfirmationModal = (notebook) => {
    setDeletingNotebookId(notebook.id);
    deleteNotebookConfirmation.current.toggleModal(true);
  };
  const deleteNotebookConfirmationModalFooter = () => {
    return (
      <div className="cx-admin-footer-actions">
        <button
          type="button"
          className="cx-button cx-button-cancel"
          tabIndex="-1"
          onClick={deleteNotebookConfirmationModalCancel}
        >
          {lang.cancel}
        </button>
        <button type="submit" className="cx-button cx-button-delete" onClick={deleteNotebook}>
          {lang.delete}
        </button>
      </div>
    );
  };

  const deleteNotebook = async () => {
    setIsLoading(true);
    try {
      await del(`notebook/${deletingNotebookId}`);
      setDatabind(randomService.getRandomString(20));
      deleteNotebookConfirmation.current.toggleModal(false);
    } finally {
      setIsLoading(false);
    }
  };
  const deleteNotebookConfirmationModalCancel = () => {
    setDeletingNotebookId(null);
    deleteNotebookConfirmation.current.toggleModal(false);
  };

  const notebookFormEventHandler = (action) => {
    switch (action) {
      case 'saved':
        setShowEditForm(false);
        setDatabind(randomService.getRandomString(20));

        break;
      case 'canceled':
        setShowEditForm(false);
        break;

      default:
        break;
    }
  };

  const handleAllNotesClick = () => {
    history.push('/notes');
  };
  const onNewNoteClick = () => {
    history.push({ pathname: '/notes', state: 'onNewNoteClick' });
  };

  const handleSearch = (search) => {
    console.log(search);
  };

  // Notebook.Set As Default
  const setAsDefaultClick = async (notebook) => {
    const response = await put(`notebook/${notebook.id}/set-as-default`);
    enqueueSnackbar(response, { variant: 'success' });
    setDatabind(randomService.getRandomString(20));
  };

  // Notebook.Share
  const [showShare, setShowShare] = useState(false);
  const [notebookToShareId, setNotebookToShareId] = useState(false);
  const sharingModal = useRef();
  const shareNotebookClick = (notebook) => {
    setNotebookToShareId(notebook.id);
    setShowShare(true);
    sharingModal.current.toggleModal(true);
  };

  const shareNotebookFormEventHandler = (action) => {
    switch (action) {
      case 'saved':
        sharingModal.current.toggleModal(false);
        break;
      case 'canceled':
        sharingModal.current.toggleModal(false);
        break;
      default:
        break;
    }
  };

  const myNotebooks = notebooks.filter((x) => x.sharedWithMe === false);

  const sharedItems = notebooks.filter((x) => x.sharedWithMe === true);

  return (
    <>
      <Breadcrumb items={breadcrumb} />
      <div className="cx-notebooks" style={{ height: noteContainerHeight + 'px' }}>
        <NotesNav
          lang={lang}
          history={history}
          notebooks={notebooks}
          selectedNotebook={workingNotebook}
          onNotebookSelected={onNotebookSelected}
          onNewNoteClick={onNewNoteClick}
          onAllNotesClick={handleAllNotesClick}
          onSearch={handleSearch}
          breakPoint={breakPoint}
        />

        <div className="cx-notebooks-container">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {showEditForm ? (
                <NotebookEditForm
                  lang={lang}
                  notebook={notebook}
                  notebooks={notebooks}
                  formEventHandler={notebookFormEventHandler}
                  contentGroupId={contentGroup.id}
                />
              ) : (
                <div className="cx-notebooks-list-container">
                  <div className="cx-admin-top-actions">
                    <button className="cx-button cx-button-new" onClick={(x) => newNotebookClick(null)}>
                      {lang.newNotebook}
                    </button>
                  </div>

                  <div className="cx-notebooks-list" style={{ height: noteContainerHeight - 76 + 'px' }}>
                    <NotebookItem
                      lang={lang}
                      workingNotebook={workingNotebook}
                      title={lang.myNotebooks}
                      items={myNotebooks}
                      type="owned"
                      onNotebookSelected={onNotebookSelected}
                      onSetDefault={setAsDefaultClick}
                      onShareNotebook={shareNotebookClick}
                      onEditNotebook={editNotebookClick}
                      onDeleteNotebook={openDeleteNotebookConfirmationModal}
                    />
                    {sharedItems.length ? (
                      <NotebookItem
                        lang={lang}
                        workingNotebook={workingNotebook}
                        title={lang.sharedNotebooks}
                        items={sharedItems}
                        type="not-owned"
                        onNotebookSelected={onNotebookSelected}
                        onEditNotebook={editNotebookClick}
                        onDeleteNotebook={openDeleteNotebookConfirmationModal}
                      />
                    ) : null}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <Modal
        ref={deleteNotebookConfirmation}
        title={lang.deleteNotebookConfirmationMessageTitle}
        footer={deleteNotebookConfirmationModalFooter()}
        className="cx-notebooks-delete-modal"
      >
        <p>{lang.deleteNotebookConfirmationMessageBody}</p>
      </Modal>

      <Modal ref={sharingModal} title={lang.shareNotebook}>
        {showShare ? (
          <Share
            workspaceId={workspaceId}
            contentType="notebook"
            contentId={notebookToShareId}
            shareFormEventHandler={shareNotebookFormEventHandler}
            sharedSuccessMessage={lang.notebookShared}
          />
        ) : null}
      </Modal>
    </>
  );
};

const mapStateToProps = (state, props = {}) => {
  return {
    lang: state.app.lang,
    workspaceId: state.app.workspace.id,
    contentGroup: state.app.contentGroup,
    workingNotebook: state.notes.workingNotebook,
    breakPoint: state.responsive.breakPoint,
  };
};
const mapDispatchToProps = {
  setWorkingNotebook,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NotebookContainer));
