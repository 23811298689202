import React, { useEffect, useState, useRef } from 'react';
import './todo.scss';
import PropTypes from 'prop-types';

import useApi from '../../../core/useApi';

import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import TextField from '@mui/material/TextField';

import { Modal, Panel, DropDownMenu, Share, CxConfirm } from '../../../core/components';
import { TodoItem } from './todoItem';

const Todo = ({ todo, editTodoClick, editTodoFormEventHandler }) => {
  const lang = useSelector((state) => state.app.lang);
  const workspace = useSelector((state) => state.app.workspace);
  const { get, post, del } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [showShare, setShowShare] = useState(false);

  // FORM
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // Todo
  const onEditTodoClick = () => {
    editTodoClick(todo.id);
  };

  // Todo.Delete
  const [showTodoDeleteConfirm, setShowTodoDeleteConfirm] = useState(false);

  const onDeleteTodoClick = () => {
    setShowTodoDeleteConfirm(true);
  };

  const handleTodoDeleteCancel = () => {
    setShowTodoDeleteConfirm(false);
  };

  const handleTodoDeleteConfirm = async () => {
    await del(`todo/${todo.id}`);
    setShowTodoDeleteConfirm(false);
    editTodoFormEventHandler('delete');
    enqueueSnackbar(lang.todoCardDeleted, {
      variant: 'success',
    });
  };

  // Todo.Share
  const sharingModal = useRef();
  const onShareTodoClick = () => {
    setShowShare(true);
    sharingModal.current.toggleModal(true);
  };

  const shareTodoFormEventHandler = (action) => {
    switch (action) {
      case 'saved':
        sharingModal.current.toggleModal(false);
        break;
      case 'canceled':
        sharingModal.current.toggleModal(false);
        break;
      default:
        break;
    }
  };

  // Todo.List Items
  const [items, setItems] = useState([]);

  const getTodoItems = async () => {
    const response = await get(`todo/${todo.id}/items`);
    setItems(response);
  };

  useEffect(() => {
    getTodoItems();
  }, [todo]);

  const addTodoItemClick = async (item) => {
    await post(`todo/${todo.id}/items`, item);
    reset();
    await getTodoItems();
  };

  const todoItemEventHandler = async (action) => {
    switch (action) {
      case 'updated':
        await getTodoItems();
        break;
      default:
        throw Error('todoItemEventHandler action not implemented;');
    }
  };

  const list = items.map((item) => (
    <TodoItem key={item.key} item={item} lang={lang} todoItemEventHandler={todoItemEventHandler} />
  ));

  const dropDownMenuOptions = [
    { icon: 'icon-pencil', text: lang.edit, action: onEditTodoClick },
    { icon: 'icon-trash', text: lang.delete, action: onDeleteTodoClick },
    { icon: 'icon-share', text: lang.shareTodo, action: onShareTodoClick },
  ];

  return (
    <>
      <Panel id={todo.key} title={todo.title} className="cx-todo">
        <header>
          <DropDownMenu id={todo.key} items={dropDownMenuOptions} buttonIcon="icon-cog" />
        </header>

        <div className="cx-todo-items">
          <div className="form">
            <form onSubmit={handleSubmit(addTodoItemClick)}>
              <div>
                <TextField
                  id="title"
                  name="title"
                  type="text"
                  label={lang.title}
                  {...register('title', {
                    required: { value: true, message: lang.validationFieldRequired },
                  })}
                  fullWidth
                  variant="standard"
                />

                {errors.title && <span>{lang.validationFieldRequired}</span>}
              </div>
              <div>
                <button type="submit" className="cx-button cx-button-save">
                  {lang.add}
                </button>
              </div>
            </form>
          </div>
          <ul>{list}</ul>
        </div>
      </Panel>

      <CxConfirm
        lang={lang}
        type="delete"
        message={`${lang.deleteConfirmationMessage}"${todo.title}"?`}
        show={showTodoDeleteConfirm}
        confirmButtonText={lang.delete}
        onConfirmClick={handleTodoDeleteConfirm}
        onCancelClick={handleTodoDeleteCancel}
      />

      <Modal ref={sharingModal} title={lang.shareTodo}>
        {showShare ? (
          <Share
            workspaceId={workspace.id}
            contentType="todo"
            contentId={todo.id}
            shareFormEventHandler={shareTodoFormEventHandler}
            sharedSuccessMessage={lang.todoShared}
          />
        ) : null}
      </Modal>
    </>
  );
};

Todo.propTypes = {
  lang: PropTypes.object.isRequired,
  todo: PropTypes.object.isRequired,
  editTodoClick: PropTypes.func.isRequired,
  editTodoFormEventHandler: PropTypes.func.isRequired,
};

export default Todo;
