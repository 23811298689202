import React, { useEffect } from 'react';
import './slideShow.scss';
import PropTypes from 'prop-types';
import Slide from './slide';
import { SlideDot } from './slideDot';

const SlideShow = ({ images, index = 0, className }) => {
  let slideIndex = index;
  let slides = [];
  let dots = [];

  useEffect(() => {
    showSlide(slideIndex);
  });

  // Next/previous controls
  const plusSlides = (n) => {
    slideIndex += n;
    if (slideIndex > slides.length - 1) {
      slideIndex = 0;
    }
    if (slideIndex < 0) {
      slideIndex = slides.length - 1;
    }
    showSlide(slideIndex);
  };

  const reset = () => {
    // Reset slides
    const slideItems = document.getElementsByClassName('mySlides');
    for (let s = 0; s < slideItems.length; s++) {
      const element = slideItems[s];
      element.style.display = 'none';
    }

    // Reset dots
    const dotItems = document.getElementsByClassName('dot');
    for (let d = 0; d < dotItems.length; d++) {
      const element = dotItems[d];
      element.classList.remove('active');
    }
  };

  const showSlide = (index) => {
    reset();
    const id = slides[index].key;
    document.getElementsByClassName(`slide-${id}`)[0].style.display = 'block';
    document.getElementsByClassName(`dot-${id}`)[0].className += ' active';
  };

  for (let i = 0; i < images.length; i++) {
    const img = images[i];
    slides.push(<Slide key={img.id} image={img} page={i + 1} total={images.length} />);
    dots.push(<SlideDot key={img.id} index={i} id={img.id} onDotClick={showSlide} />);
  }

  return (
    <div className={className == null ? 'cx-slide-show' : `cx-slide-show ${className}`}>
      <div className="slideshow-container">
        {slides}

        <button className="cx-nobutton prev" onClick={(x) => plusSlides(-1)}>
          &#10094;
        </button>
        <button className="cx-nobutton next" onClick={(x) => plusSlides(1)}>
          &#10095;
        </button>
      </div>
      <br />
      <div style={{ textAlign: 'center' }}>{dots}</div>
    </div>
  );
};

SlideShow.propTypes = {
  images: PropTypes.array.isRequired,
  index: PropTypes.number,
  className: PropTypes.string,
};

export default SlideShow;
