import React, { useEffect, useState } from 'react';
import './dashboard.scss';

// State
import { connect } from 'react-redux';
import { setApps } from '../../redux/accountApps/accountApps.actions';
import { setWorkspace, setContentGroup } from '../../redux/app/app.actions';

import useApi from '../../../core/useApi';
import MyProfile from './widgets/myProfile';
import QuickActions from './widgets/quickActions';
import { Breadcrumb, Loader } from '../../../core/components';

const Dashboard = ({ lang, history, workspace, contentGroup, setApps, setWorkspace, setContentGroup }) => {
  const { get } = useApi();

  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const breadcrumb = [{ name: lang.dashboard, path: null }];

  // Workspace
  const getDefaultWorkSpace = async () => {
    if (workspace === null) {
      const response = await get('core/workspace/default');
      setWorkspace(response);
    }
  };

  // Content Group
  const getDefaultContentGroup = async () => {
    if (contentGroup === null) {
      const response = await get('core/content-group/default');
      setContentGroup(response);
    }
  };

  // Dashboard Apps
  const getDashboardApps = async () => {
    setIsLoading(true);
    try {
      const response = await get('account/apps');
      setItems(response);
      setApps(response);
      
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDefaultWorkSpace();
    getDefaultContentGroup();
    getDashboardApps();
  }, []);

  const onCardClick = (path) => {
    history.push(path);
  };

  const list = items.map((item) => (
    <div className="cx-user-apps-item" key={item.key}>
      <div
        id={item.key}
        onClick={(x) => {
          onCardClick(item.link);
        }}
        className={'cx-user-apps-item-container ' + item.cssClass}
        key={item.appId}
      >
        <h2>
          <i className={item.icon}></i>
          <span>{item.name}</span>
        </h2>
        <p>{item.description}</p>
      </div>
    </div>
  ));

  return (
    <>
      <Breadcrumb items={breadcrumb} />
      <div className="cx-dashboard">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="cx-dashboard-dynamic">
              <div className="cx-user-apps">{list}</div>
            </div>
            <div className="cx-dashboard-static">
              <div>
                <QuickActions lang={lang} />
              </div>
              <div>
                <MyProfile lang={lang} />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state, props = {}) => {
  return {
    lang: state.app.lang,
    workspace: state.app.workspace,
    contentGroup: state.app.contentGroup,
  };
};

const mapDispatchToProps = {
  setApps,
  setWorkspace,
  setContentGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
