import React from 'react';
import { configService } from '../../../core/services/config.service';

const Footer = () => {
  return (
    <div className="cx-footer">
      <p>© {new Date().getFullYear()} Dakity LLC</p>
      <p>{configService.appVersion}</p>
    </div>
  );
};

export default Footer;
