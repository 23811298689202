export class BrewerySearchDto {
  text = '';
  sort = 'name';
  sortDirection = 'asc';
}

export class BrewerySearchRequest {
  limit = 1000;
  skip = 0;
  count = 0;
  search = new BrewerySearchDto();
}
