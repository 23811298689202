import React, { useEffect, useState, useRef } from 'react';
import './BeerContainer.scss';

import useApi from '../../../core/useApi';
import responsive from '../../../core/responsive';
import { Breadcrumb, Loader, EmptyState, BrowserWindowsReachBottomScroll } from '../../../core/components';

// State
import { useSelector } from 'react-redux';

// Mui
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';

import { BeerSearchRequest } from './beerSearch.dto';
import BeerItem from './BeerItem';

const BeerContainer = ({ history }) => {
  const { get, parseQuery } = useApi();
  const lang = useSelector((state) => state.app.lang);
  const breadcrumb = [
    { name: lang.dashboard, path: '/dashboard' },
    { name: lang.beers, path: null },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [searchRequest, setSearchRequest] = useState(new BeerSearchRequest());
  const [isSearching, setIsSearching] = useState(false);
  const searchRef = useRef();

  // Responsive ====================================================================
  const [isLarge] = useState(window.innerWidth >= responsive.mdMinWidth);

  // Databind

  const getBeers = async (search, loadMore) => {
    setIsLoading(true);
    try {

      const queryParams = parseQuery(search);
      const response = await get(`beers/search${queryParams}`);

      if (loadMore) {
        var newItems = [...items];
        setItems(newItems.concat(response.items));
        search.showingCount = newItems.length;

      } else {
        setItems(response.items);
        search.showingCount = response.items.length;

      }

      search.count = response.count;
      search.limit = response.limit;
      search.skip = response.skip;
      setSearchRequest(search);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBeers(searchRequest, false);
  }, []);

  const searchClick = () => {
    const request = { ...searchRequest };
    request.search.text = searchRef.current.value;
    request.skip = 0;
    console.log(request);
    getBeers(request, false);
    setIsSearching(true);
  };
  const clearSearchClick = () => {
    searchRef.current.value = null;
    const request = { ...searchRequest };
    request.search.text = null;
    request.skip = 0;
    getBeers(request, false);
    setIsSearching(false);
  };

  const handleLoadMore = () => {
    const request = { ...searchRequest };
    request.skip = parseInt(request.skip) + parseInt(request.limit);
    getBeers(request, true);
  };

  const newBeerClick = () => {
    history.push(`/beers/new`);
  };
  const handleBeerItemClick = (item) => {
    history.push(`/beers/${item.id}`);
  };
  const handleBeerItemLinkClick = (item) => {
    console.log(searchRef.current);
    searchRef.current.value = item;
  };

  // Filter pop over
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleSortClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onSortClick = (sortField) => {
    const request = { ...searchRequest };
    const { sort } = request.search;

    if (sortField === sort) {
      request.search.sortDirection = request.search.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      request.search.sort = sortField;
      request.search.sortDirection = 'asc';
    }

    request.skip = 0;
    setSearchRequest(request);
    getBeers(request);
    setAnchorEl(null);
  };

  return (
    <div className="cx-beers">
      <Breadcrumb items={breadcrumb} />
      <div className="cx-beers-search">
        <Paper component="form" sx={{ p: '0px 8px', display: 'flex', alignItems: 'center' }}>
          <InputBase
            type="text"
            inputRef={searchRef}
            placeholder={lang.searchBeer}
            sx={{ ml: 1, flex: 1 }}
            inputProps={{ 'aria-label': lang.searchBeer }}
          />
          {isSearching ? (
            <IconButton type="button" aria-label="clear-search" onClick={clearSearchClick} size="large">
              <i className="icon-times" />
            </IconButton>
          ) : (
            <IconButton type="button" aria-label="search" onClick={searchClick} size="large">
              <i className="icon-search" />
            </IconButton>
          )}
          <Divider orientation="vertical" />
          <IconButton type="button" aria-label="sort" onClick={handleSortClick} size="large">
            <i className="icon-sort-amount-asc" />
          </IconButton>
          <Divider orientation="vertical" />
          <button type="button" className="cx-button cx-button-new" onClick={newBeerClick}>
            {isLarge ? lang.newBeer : <i className="icon-plus" />}
          </button>
        </Paper>
      </div>

      {isLoading && items.length === 0 ? (
        <Loader />
      ) : (
        <>
          {!isSearching && items.length === 0 ? (
            <EmptyState
              title={lang.welcomeToTheBeerCoolerTitle}
              description={lang.welcomeToTheBeerCoolerDesc}
              onActionClick={newBeerClick}
              actionText={lang.newBeer}
            />
          ) : (
            <>
              <div className='cx-beers-total'>
                {lang.showing} {searchRequest.showingCount} {lang.of} {searchRequest.count} {lang.totalBeersInCooler}
              </div>
              <div className="cx-beers-list">
                {items.map((item) => (
                  <BeerItem
                    key={item.id}
                    lang={lang}
                    item={item}
                    onBeerItemClick={handleBeerItemClick}
                    onBeerItemLinkClick={handleBeerItemLinkClick}
                  />
                ))}
              </div>

              <div className="cx-beers-load-more">
                <BrowserWindowsReachBottomScroll padding={3} action={handleLoadMore} />
              </div>
            </>
          )}

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div className="cx-beer-search-sort">
              <h3>Sort By</h3>
              <ul>
                <li onClick={() => onSortClick('rating')}>
                  Rating
                  {searchRequest.search.sort === 'rating' ? (
                    searchRequest.search.sortDirection === 'asc' ? (
                      <i className="icon-sort-amount-asc" />
                    ) : (
                      <i className="icon-sort-amount-desc" />
                    )
                  ) : null}
                </li>
                <li onClick={() => onSortClick('abv')}>
                  ABV %
                  {searchRequest.search.sort === 'abv' ? (
                    searchRequest.search.sortDirection === 'asc' ? (
                      <i className="icon-sort-amount-asc" />
                    ) : (
                      <i className="icon-sort-amount-desc" />
                    )
                  ) : null}
                </li>
                <li onClick={() => onSortClick('brewery')}>
                  Brewery
                  {searchRequest.search.sort === 'brewery' ? (
                    searchRequest.search.sortDirection === 'asc' ? (
                      <i className="icon-sort-amount-asc" />
                    ) : (
                      <i className="icon-sort-amount-desc" />
                    )
                  ) : null}
                </li>
                <li onClick={() => onSortClick('style')}>
                  Style
                  {searchRequest.search.sort === 'style' ? (
                    searchRequest.search.sortDirection === 'asc' ? (
                      <i className="icon-sort-amount-asc" />
                    ) : (
                      <i className="icon-sort-amount-desc" />
                    )
                  ) : null}
                </li>
                <li onClick={() => onSortClick('name')}>
                  Name
                  {searchRequest.search.sort === 'name' ? (
                    searchRequest.search.sortDirection === 'asc' ? (
                      <i className="icon-sort-amount-asc" />
                    ) : (
                      <i className="icon-sort-amount-desc" />
                    )
                  ) : null}
                </li>
                <li onClick={() => onSortClick('createDate')}>
                  Added
                  {searchRequest.search.sort === 'createDate' ? (
                    searchRequest.search.sortDirection === 'asc' ? (
                      <i className="icon-sort-amount-asc" />
                    ) : (
                      <i className="icon-sort-amount-desc" />
                    )
                  ) : null}
                </li>
              </ul>
            </div>
          </Popover>
        </>
      )}
    </div>
  );
};

export default BeerContainer;
