import React, { useEffect, useState } from 'react';
import './PreNotification.scss';

import useApi from '../../../core/useApi';
import { Loader, EmptyState, Breadcrumb, Card } from '../../../core/components';

// State
import { useSelector } from 'react-redux';

const PreNotification = ({ history, match, location, id, title }) => {
  const { get } = useApi();
  const lang = useSelector((state) => state.app.lang);
  const breadcrumb = [{ name: lang.notifications, path: null }];
  const [notificationId] = useState(match.params.id);
  const [message, setMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getPreNotification = async () => {
    setIsLoading(true);
    try {
      const response = await get(`notifications/pre/${notificationId}`);
      setMessage(JSON.parse(response.notification.message));
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (location.pathname.includes('/notifications/pre/')) {
      getPreNotification();
    }
  }, []);

  const onActionButtonClick = () => {
    sessionStorage.setItem('pre.path.workflow', message.notificationType);
    sessionStorage.setItem('pre.path.workflow.id', notificationId);
    history.push('/dashboard');
  };

  const getNotification = (notificationType) => {
    console.log(notificationType);
    switch (notificationType) {
      case 'Notebook':
        var msg2 = lang.notebookSharedPreNotificationInstructions2;
        msg2 = msg2.replace('#fromName', message.fromName);
        msg2 = msg2.replace('#notificationType', notificationType);
        msg2 = msg2.replace('#contentName', message.content.name);

        var msg3 = lang.notebookSharedPreNotificationInstructions3;

        return (
          <>
            <p>{lang.notebookSharedPreNotificationInstructions1.replace('#toName', message.toName)}</p>
            <p>{msg2}</p>
            <p>{msg3}</p>
          </>
        );
      default:
        return <EmptyState />;
    }
  };

  return (
    <>
      <Breadcrumb items={breadcrumb} />

      <div className="cx-pre-notification">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Card>
              <div>
                {getNotification(message.notificationType)}
                <button type="button" className="cx-button cx-button-action" onClick={onActionButtonClick}>
                  {lang.continue}
                </button>
              </div>
            </Card>
          </>
        )}
      </div>
    </>
  );
};

export default PreNotification;
