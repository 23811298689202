import { setAuthHeader } from '../../../../core/axiosHeaders';
import { LOGIN_SUCCESS, LOGOUT } from './actions';
import { jwtDecode } from 'jwt-decode';

const LOCAL_STORAGE_KEY = 'user';

const getUser = () => {
  try {
    const storedUser = localStorage.getItem(LOCAL_STORAGE_KEY);

    // User not in storage. Auth not valid.
    if (storedUser === null) {
      return null;
    }
    var user = JSON.parse(storedUser);

    // Validate if token is not expired.
    const decodedToken = jwtDecode(user.token.access_token);
    const currentTime = Date.now() / 1000; // Convert to seconds

    if (decodedToken.exp < currentTime) {
      removeUser();
      return null;
    }

    // All good return user.
    setAuthHeader(user.token.access_token);
    return user;
  } catch {
    removeUser();
    return null;
  }
};

const setUser = (user) => {  
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(user));
  setAuthHeader(user.token.access_token);
};

const isAuth = () => {
  const storedUser = localStorage.getItem(LOCAL_STORAGE_KEY);
  return storedUser !== null;
};
const removeUser = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEY);
  console.log('remove user');
  // return null;
};

const initialState = {
  user: getUser(),
  isAuthenticated: isAuth(),
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      setUser(action.data);

      return {
        ...state,
        user: action.data,
        isAuthenticated: isAuth(),
      };
    case LOGOUT:
      removeUser();
      return {
        ...state,
        user: null,
        isAuthenticated: isAuth(),
      };

    default:
      return state;
  }
};

export default auth;
