import React from 'react';
import './header.scss';
import ActionMenu from './actionMenu/actionMenu';

export const Header = React.memo(({ lang, isLarge, onSideNavToggle }) => {
  const toggleSideNavigation = () => {
    onSideNavToggle();
  };

  return (
    <header className="cx-header">
      <button className="hamburger" title="Show/Hide side menu" onClick={toggleSideNavigation}>
        <i className="icon-bars" />
      </button>
      <ActionMenu lang={lang} isLarge={isLarge} />
    </header>
  );
});
