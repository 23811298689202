import React, { useEffect, useState } from 'react';
import './AccountValidateCode.scss';

import { useHistory } from 'react-router-dom';

import useApi from '../../../../core/useApi';

// State
import { useSelector } from 'react-redux';
import queryString from 'query-string';

// Forms
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// UI
import { Loader, Panel } from '../../../../core/components';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';

const AccountValidateCode = ({ location }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const lang = useSelector((state) => state.app.lang);
  const [isLoading, setIsLoading] = useState(false);

  const { get, post } = useApi();

  // Get password rules
  const [passwordRules, setPasswordRules] = useState();
  const getPasswordRules = async () => {
    const parsed = queryString.parse(location.search);
    setValue('email', parsed.email);

    const response = await get(`account/activation?email=${parsed.email}`);
    setPasswordRules(response.passwordRules);
  };

  useEffect(() => {
    getPasswordRules();
  }, []);

  // Form
  const schema = yup
    .object({
      code: yup.string().required(lang.validationFieldRequired).min(10),
      newPassword: yup.string().required(lang.validationFieldRequired).min(8),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('newPassword'), null], lang.passwordDoNotMatch)
        .required(lang.validationFieldRequired)
        .min(8),
    })
    .required();

  // Form
  const {
    handleSubmit,
    setValue,
    getValues,
    register,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      code: '',
      newPassword: '',
      confirmPassword: '',
    },
  });

  const [showRequestNewCode, setShowRequestNewCode] = useState(false);

  const create = async (data) => {
    setIsLoading(true);
    try {
      await post(`account/activation`, data);

      enqueueSnackbar(lang.accountActivationCompleted, {
        variant: 'success',
      });

      history.push({
        pathname: '/login',
      });
    } catch (ex) {
      setShowRequestNewCode(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = (data) => {
    create(data);
  };

  // Request New Code

  const requestNewCode = async (data) => {
    setIsLoading(true);
    try {
      await post(`account/password/request-code`, { email: data });

      console.log(lang.newTemporaryCodeSent);

      enqueueSnackbar(lang.newTemporaryCodeSent, {
        variant: 'success',
      });

      setShowRequestNewCode(false);
    } catch (ex) {
      console.log(ex);
      setShowRequestNewCode(true);
    } finally {
      setIsLoading(false);
    }
  };

  const requestNewCodeClick = (data) => {
    const email = getValues('email');
    requestNewCode(email);
  };

  const cancelClick = () => {
    history.push({
      pathname: '/login',
    });
  };

  return (
    <div className="cx-account-validation-code">
      <h2>{lang.accountValidateCode}</h2>
      <div>
        <Panel title={lang.createPassword}>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <p>{lang.welcomeToDakityInstructions}</p>
              <form id="account-validation" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <input type="hidden" name="email" {...register('email')} />
                <div>
                  <Controller
                    control={control}
                    name="code"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={lang.accountActivationCode}
                        type="text"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        inputProps={{
                          autoComplete: 'new-password',
                          form: {
                            autoComplete: 'off',
                          },
                        }}
                        error={!!errors.code}
                        helperText={errors.code ? errors.code.message : ''}
                      />
                    )}
                  />
                </div>
                <div>
                  <Controller
                    control={control}
                    name="newPassword"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={lang.password}
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        inputProps={{
                          autoComplete: 'new-password',
                          form: {
                            autoComplete: 'off',
                          },
                        }}
                        error={!!errors.newPassword}
                        helperText={errors.newPassword ? errors.newPassword.message : ''}
                      />
                    )}
                  />
                </div>
                <div>
                  <Controller
                    control={control}
                    name="confirmPassword"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={lang.confirmPassword}
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword ? errors.confirmPassword.message : ''}
                      />
                    )}
                  />
                </div>

                <div className="cx-form-buttons">
                  {showRequestNewCode ? (
                    <button type="button" className="cx-button cx-button-action new-code" onClick={requestNewCodeClick}>
                      {lang.requestNewCode}
                    </button>
                  ) : null}

                  <button type="button" className="cx-button cx-button-cancel" onClick={cancelClick}>
                    {lang.cancel}
                  </button>

                  <input type="submit" className="cx-button cx-button-save" value={lang.create} />
                </div>
              </form>
            </>
          )}
        </Panel>
        {passwordRules ? (
          <Panel title={lang.passwordRules} className="cx-password-rules">
            <b>Here are the guidelines for creating your password: </b>
            <ul>
              <li>Should have a minimum of {passwordRules.passwordMinLength} characters</li>

              {passwordRules.passwordMinUppercase > 0 ? (
                <li>At least {passwordRules.passwordMinUppercase} uppercase characters </li>
              ) : null}

              {passwordRules.passwordMinNumeric > 0 ? (
                <li>At least {passwordRules.passwordMinNumeric} numbers.</li>
              ) : null}

              {passwordRules.passwordAllowedSpecialChars ? (
                <li>Characters allowed, A to Z, 0 to 9 and {passwordRules.passwordAllowedSpecialChars} only</li>
              ) : null}
            </ul>
          </Panel>
        ) : null}
      </div>
    </div>
  );
};

export default AccountValidateCode;
