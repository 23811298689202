import React from 'react';
import './UserAction.scss';

import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

const UserAction = ({ history }) => {
  const user = useSelector((state) => state.auth.user);

  const navigateToProfile = () => {
    history.push('/account/settings');
  };

  return user ? (
    <div className="cx-header-actions-user" onClick={navigateToProfile}>
      <i className="icon-user" />
      <span>{user.profile.name}</span>
    </div>
  ) : null;
};

export default withRouter(UserAction);
