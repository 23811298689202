import React, { useEffect, useState } from 'react';
import './WineEdit.scss';

import { withRouter } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

// State
import { useSelector } from 'react-redux';

// Mui
import { useSnackbar } from 'notistack';
import Dropzone from 'react-dropzone';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import useApi from '../../../core/useApi';
import { Breadcrumb, Loader, NoImagePlaceholder, CxSlider, CxSelect } from '../../../core/components';
import { helpers } from '../../../core/helpers';
import { WineStyleSearchRequest } from './WineStyleSearch.dto';
import { GrapeSearchRequest } from './GrapeSearch.dto';

const filter = createFilterOptions();

const WineEdit = ({ history, match }) => {
  const { get, post, postWithUpload, putWithUpload, del, upload, parseQuery } = useApi();
  const lang = useSelector((state) => state.app.lang);
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm({
    defaultValues: {
      id: '',
      name: '',
      drinkPlace: '',
      alcoholPerVolume: '',
      tastingNote: '',
      price: '',
      year: '',
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [wineId] = useState(match.params.id);

  const breadcrumb = [
    { name: lang.dashboard, path: '/dashboard' },
    { name: lang.wines, path: '/wines' },
    { name: lang.edit, path: null },
  ];

  // Year
  const [selectedYear, setSelectedYear] = useState('');
  const years = helpers.generateArrayOfYears().map((year) => ({ name: year, value: year }));
  const handleYearChange = (value) => {
    setSelectedYear(value);
    setValue('year', value, true);
  };

  // Grape
  const [grapes, setGrapes] = useState([]);
  const [grape, setGrape] = React.useState(null);
  const [openGrapeDialog, toggleOpenGrapeDialog] = React.useState(false);

  const getGrapes = async () => {
    const request = new GrapeSearchRequest();
    const response = await get(`grapes${parseQuery(request)}`);
    setGrapes(response);
  };

  const handleGrapeDialogClose = () => {
    setGrapeDialogValue({
      name: '',
      id: null,
    });

    toggleOpenGrapeDialog(false);
  };

  const [grapeDialogValue, setGrapeDialogValue] = React.useState({
    name: '',
    id: '',
  });

  const handleGrapeSubmit = async (event) => {
    event.preventDefault();

    const newGrape = {
      name: grapeDialogValue.name,
    };

    const response = await post('grapes', newGrape);
    setGrape(response);
    handleGrapeDialogClose();
  };

  // Wine Style
  const [styles, setStyles] = useState([]);
  const [style, setStyle] = React.useState(null);
  const [openStyleDialog, toggleOpenStyleDialog] = React.useState(false);

  const getWineStyles = async () => {
    setIsLoading(true);
    try {
      const request = new WineStyleSearchRequest();
      const response = await get(`wine-styles${parseQuery(request)}`);
      setStyles(response);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStyleDialogClose = () => {
    setStyleDialogValue({
      name: '',
      id: null,
    });

    toggleOpenStyleDialog(false);
  };

  const [styleDialogValue, setStyleDialogValue] = React.useState({
    name: '',
    id: '',
  });

  const handleStyleSubmit = async (event) => {
    event.preventDefault();

    const newStyle = {
      name: styleDialogValue.name,
    };

    const response = await post('wine-styles', newStyle);
    setStyle(response);
    handleStyleDialogClose();
  };

  // Rating
  const [rating, setRating] = useState(1);
  const handleRatingValueChange = (value) => {
    setRating(value);
  };
  const getSliderValueText = (value) => {
    return `${value}`;
  };

  const getWine = async () => {
    const response = await get(`wines/${wineId}`);

    reset({
      id: response.id,
      name: response.name,
      price: response.price ?? '',
      year: response.year,
      drinkPlace: response.drinkPlace ?? '',
      alcoholPerVolume: response.alcoholPerVolume ?? '',
      tastingNote: response.tastingNote ?? '',
    });

    setImg(response.imageUrl);

    handleRatingValueChange(response.rating);

    //console.log(response.rating);

    if (response.grapeId) {
      setGrape({ id: response.grapeId, name: response.grape });
    }

    if (response.styleId) {
      setStyle({ id: response.styleId, name: response.style });
    }

    if (response.year) {
      setSelectedYear(response.year);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    try {
      getWineStyles();
      getGrapes();

      if (grapes && styles && wineId !== 'new') {
       getWine();
      }
    } finally {
      setIsLoading(false);
    }
  }, [wineId]);

  const onCancelClick = () => {
    history.push('/wines');
  };

  // Delete Wine
  const [openDeleteDialog, toggleOpenDeleteDialog] = React.useState(false);

  const handleDeleteDialogClose = () => {
    toggleOpenDeleteDialog(false);
  };

  const onDeleteConfirmClick = () => {
    toggleOpenDeleteDialog(true);
  };
  const onDeleteClick = async () => {
    await del(`wines/${wineId}`);
    toggleOpenDeleteDialog(false);
    enqueueSnackbar(lang.wineDeleted, {
      variant: 'success',
    });
    history.push('/wines');
  };

  // Save
  const onSaveClick = async (data) => {
    const entity = new FormData();

    if (tempImgData) {
      entity.append('files', tempImgData);
    }
    entity.append('name', data.name);
    entity.append('price', data.price ?? '');
    entity.append('year', selectedYear);
    entity.append('drinkPlace', data.drinkPlace);
    entity.append('alcoholPerVolume', data.alcoholPerVolume);
    entity.append('tastingNote', data.tastingNote);
    entity.append('rating', rating);

    if (grape && grape.id) entity.append('grapeId', grape.id);

    if (style && style.id) entity.append('styleId', style.id);

    const isNew = data.id === '';
    if (isNew) {
      await postWithUpload('wines', entity);
      enqueueSnackbar(lang.wineAddedToCellar, {
        variant: 'success',
      });
      history.push('/wines');
    } else {
      await putWithUpload(`wines/${data.id}`, entity);
      enqueueSnackbar(lang.wineUpdated, {
        variant: 'success',
      });
    }
  };

  // Image Upload
  const [tempImgData, setImgData] = useState();
  const [tempImg, setTempImg] = useState();
  const [img, setImg] = useState();
  const myUploader = async (files) => {
    setImgData(files[0]);

    // Temp Upload
    const data = new FormData();
    data.append('file', files[0]);
    const response = await upload(`core/temp-image-upload?width=350&height=350`, data);
    setTempImg(response);
  };

  return (
    <>
      <Breadcrumb items={breadcrumb} />
      <div className="cx-wines-edit">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div>
              <form className="cx-wines-edit-form" onSubmit={handleSubmit(onSaveClick)}>
                <input type="hidden" {...register('id')} />

                <div className="cx-wines-edit-img">
                  {tempImg ? (
                    <img src={`data:image/jpg;charset=utf-8;base64,${tempImg}`} alt="wine" />
                  ) : img ? (
                    <img src={`${img}&s=450`} alt="wine" />
                  ) : null}
                  <div className="upload-actions">
                    <Dropzone onDrop={myUploader}>
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps({ multiple: false })}>
                            <input {...getInputProps()} />
                            {img || tempImg ? null : <NoImagePlaceholder multi={false} />}
                            <p>{lang.dropWinePhoto}</p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                </div>

                <div className="cx-wines-edit-fields">
                  <div>
                    <CxSlider
                      min={0.1}
                      max={5}
                      step={0.1}
                      defaultValue={rating}
                      onSliderValueChanged={handleRatingValueChange}
                      valueText={getSliderValueText}
                      labelText={lang.rating}
                    />
                  </div>

                  <div>
                    <Controller
                      name="name"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: { value: true, message: lang.validationFieldRequired },
                        maxLength: { value: 128, message: 'No more than 128 chars please.' },
                      }}
                      render={({ field }) => <TextField {...field} label={lang.name} variant="outlined" fullWidth />}
                    />
                    {errors.name && <span className="input-error">{lang.validationFieldRequired}</span>}
                  </div>

                  <div>
                    <CxSelect
                      name="year"
                      label={lang.year}
                      fullWidth
                      items={years}
                      defaultValue={selectedYear}
                      onChange={handleYearChange}
                    />

                    {errors.year && <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>}
                  </div>

                  <div>
                    <Autocomplete
                      value={style}
                      onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                          // timeout to avoid instant validation of the dialog's form.
                          setTimeout(() => {
                            toggleOpenStyleDialog(true);
                            setStyleDialogValue({
                              name: newValue,
                              id: null,
                            });
                          });
                        } else if (newValue && newValue.inputValue) {
                          toggleOpenStyleDialog(true);
                          setStyleDialogValue({
                            name: newValue.inputValue,
                            id: null,
                          });
                        } else {
                          setStyle(newValue);
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        if (params.inputValue !== '') {
                          filtered.push({
                            inputValue: params.inputValue,
                            name: `Add "${params.inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      id="styles"
                      options={styles}
                      getOptionLabel={(option) => {
                        // e.g value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.name;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      renderGroup={(params) => <li key={params.key}>{params.name}</li>}
                      freeSolo
                      renderInput={(params) => <TextField {...params} label={lang.style} variant="outlined" />}
                    />
                  </div>

                  <div>
                    <Autocomplete
                      value={grape}
                      onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                          // timeout to avoid instant validation of the dialog's form.
                          setTimeout(() => {
                            toggleOpenGrapeDialog(true);
                            setGrapeDialogValue({
                              name: newValue,
                              id: null,
                            });
                          });
                        } else if (newValue && newValue.inputValue) {
                          toggleOpenGrapeDialog(true);
                          setGrapeDialogValue({
                            name: newValue.inputValue,
                            id: null,
                          });
                        } else {
                          setGrape(newValue);
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        if (params.inputValue !== '') {
                          filtered.push({
                            inputValue: params.inputValue,
                            name: `Add "${params.inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      id="grapes"
                      options={grapes}
                      //getOptionLabel={(option) => (option && option.name ? option.name : '')}
                      getOptionLabel={(option) => {
                        // e.g value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.name;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      renderGroup={(params) => <li key={params.key}>{params.name}</li>}
                      freeSolo
                      renderInput={(params) => <TextField {...params} label={lang.grape} variant="outlined" />}
                    />
                  </div>

                  <div>
                    <Controller
                      name="price"
                      control={control}
                      defaultValue=""
                      rules={{ pattern: /^[1-9]\d*(\.\d+)?$/ }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="price"
                          type="number"
                          label={lang.price}
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          error={!!errors.price}
                          helperText={errors.price ? lang.validationFieldRequired : ''}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name="tastingNote"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          id="tastingNote"
                          type="text"
                          label={lang.tastingNote}
                          variant="outlined"
                          multiline
                          maxRows={3}
                          fullWidth
                          {...field} // Spread the field props to connect with react-hook-form
                          error={!!errors.tastingNote}
                          helperText={errors.tastingNote ? lang.validationFieldRequired : ''}
                        />
                      )}
                    />
                  </div>

                  <div>
                    <Controller
                      name="drinkPlace"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          id="drinkPlace"
                          name="drinkPlace"
                          type="text"
                          label={lang.drinkPlace}
                          variant="outlined"
                          fullWidth
                          {...field} // Spread the field props to connect with react-hook-form
                          error={!!errors.drinkPlace} // Show error state if validation fails
                          helperText={errors.drinkPlace ? lang.validationFieldRequired : ''} // Display error message if validation fails
                        />
                      )}
                    />
                  </div>

                  <div>
                    <Controller
                      name="alcoholPerVolume"
                      control={control}
                      defaultValue="" // Set the default value if necessary
                      rules={{
                        pattern: {
                          value: /^[1-9]\d*(\.\d+)?$/,
                          message: 'Invalid alcohol percentage',
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          id="alcoholPerVolume"
                          name="alcoholPerVolume"
                          type="text"
                          label={lang.alcoholByVolume}
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }}
                          {...field} // Spread the field props to connect with react-hook-form
                          error={!!fieldState.error} // Show error state if validation fails
                          helperText={fieldState.error ? fieldState.error.message : ''} // Display error message if validation fails
                        />
                      )}
                    />
                  </div>

                  <div className="cx-admin-footer-actions">
                    {wineId === 'new' ? null : (
                      <button
                        type="button"
                        className="cx-button cx-button-delete"
                        tabIndex="-1"
                        onClick={onDeleteConfirmClick}
                        style={{ float: 'left' }}
                      >
                        {lang.delete}
                      </button>
                    )}
                    <button type="button" className="cx-button cx-button-cancel" tabIndex="-1" onClick={onCancelClick}>
                      {lang.cancel}
                    </button>
                    <button type="submit" className="cx-button cx-button-save">
                      {lang.save}
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <Dialog open={openGrapeDialog} onClose={handleGrapeDialogClose} aria-labelledby="form-dialog-title">
              <form onSubmit={handleGrapeSubmit}>
                <DialogTitle id="form-dialog-name">{lang.addNewGrape}</DialogTitle>
                <DialogContent>
                  <DialogContentText>{lang.addNewGrapeContextMessage}</DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="grape-name"
                    value={grapeDialogValue.name}
                    onChange={(event) => setGrapeDialogValue({ ...grapeDialogValue, name: event.target.value })}
                    label="grape-name"
                    type="text"
                  />
                </DialogContent>
                <DialogActions>
                  <button type="button" className="cx-button cx-button-cancel" onClick={handleGrapeDialogClose}>
                    {lang.cancel}
                  </button>
                  <button type="submit" className="cx-button cx-button-save">
                    {lang.save}
                  </button>
                </DialogActions>
              </form>
            </Dialog>

            <Dialog open={openStyleDialog} onClose={handleStyleDialogClose} aria-labelledby="form-dialog-title">
              <form onSubmit={handleStyleSubmit}>
                <DialogTitle id="form-dialog-name">{lang.addNewWineStyle}</DialogTitle>
                <DialogContent>
                  <DialogContentText>{lang.addNewWineStyleContextMessage}</DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="wine-style-name"
                    value={styleDialogValue.name}
                    onChange={(event) => setStyleDialogValue({ ...styleDialogValue, name: event.target.value })}
                    label="wine-style-name"
                    type="text"
                  />
                </DialogContent>
                <DialogActions>
                  <button type="button" className="cx-button cx-button-cancel" onClick={handleStyleDialogClose}>
                    {lang.cancel}
                  </button>
                  <button type="submit" className="cx-button cx-button-save">
                    {lang.save}
                  </button>
                </DialogActions>
              </form>
            </Dialog>

            <Dialog
              open={openDeleteDialog}
              onClose={handleDeleteDialogClose}
              aria-labelledby="wine-delete-dialog-title"
            >
              <DialogTitle id="wine-delete-dialog-title">{lang.deleteWineConfirmation}</DialogTitle>
              <DialogActions>
                <button type="button" className="cx-button cx-button-cancel" onClick={handleDeleteDialogClose}>
                  {lang.cancel}
                </button>
                <button type="button" className="cx-button cx-button-delete" onClick={onDeleteClick}>
                  {lang.delete}
                </button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </div>
    </>
  );
};

export default withRouter(WineEdit);
