import React, { useEffect, useState } from 'react';
import './Share.scss';
import PropTypes from 'prop-types';

import useApi from '../../../core/useApi';
import Alert from '../alert/Alert';
import Loader from '../loader/Loader';
import CxSelect from '../material/CxSelect';

import { useForm } from 'react-hook-form';

// State
import { useSelector } from 'react-redux';

// Mui
import { useSnackbar } from 'notistack';
import TextField from '@mui/material/TextField';

const Share = ({ workspaceId, contentType, contentId, shareFormEventHandler }) => {
  const { post } = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const lang = useSelector((state) => state.app.lang);
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const [error] = useState(null);

  useEffect(() => {
    reset({
      workspaceId,
      contentType: contentType,
      contentId: contentId,
      name: null,
      email: null,
      accessLevel: 0,
    });
  }, [workspaceId, contentType, contentId]);

  const onSaveClick = async (content) => {
    console.log(content);

    setIsLoading(true);
    let response;
    try {
      switch (content.contentType) {
        case 'todo':
          response = await post(`todo/${content.contentId}/share`, content);
          break;
        case 'notebook':
          response = await post(`notebook/${content.contentId}/share`, content);
          break;
        default:
          throw new Error('Content not implemented');
      }

      reset();
      enqueueSnackbar(response.messages[0].message, {
        variant: 'success',
      });
      shareFormEventHandler('saved');
    } finally {
      setIsLoading(false);
    }
  };

  const onCancelClick = () => {
    reset();
    shareFormEventHandler('canceled');
  };

  // Shared Access Level
  const [accessLevel, setAccessLevel] = useState(0);
  const accessLevelList = [
    { name: 'ViewOnly', value: 0 },
    { name: 'Edit', value: 1 },
    { name: 'EditAndShare', value: 2 },
  ];

  const handleAccessLevelChange = (value) => {
    setAccessLevel(value);
    setValue('accessLevel', value, true);
  };

  return (
    <div className="cx-share">
      <section className="form">
        <form onSubmit={handleSubmit(onSaveClick)}>
          <input type="hidden" name="workspaceId" {...register('workspaceId')} />
          <input type="hidden" name="contentType" {...register('contentType')} />
          <input type="hidden" name="contentId" {...register('contentId')} />

          {isLoading ? (
            <Loader />
          ) : (
            <>
              <div>{lang.shareInstructions}</div>
              <div>
                <TextField
                  name="name"
                  label={lang.name}
                  {...register('name', {
                    required: { value: true, message: lang.validationFieldRequired },
                    maxLength: { value: 50, message: 'No more than 64 chars please.' },
                  })}
                  type="text"
                  variant="outlined"
                  fullWidth
                />
                {errors.name && <span>{lang.validationFieldRequired}</span>}
              </div>

              <div>
                <TextField
                  name="email"
                  type="text"
                  label={lang.email}
                  {...register('email', {
                    required: { value: true, message: lang.validationFieldRequired },
                    pattern: { value: /^\S+@\S+$/i, message: 'Invalid email format.' },
                  })}
                  variant="outlined"
                  fullWidth
                />
                {errors.email && <span>{lang.validationFieldRequired}</span>}
              </div>

              {accessLevelList.length > 0 ? (
                <div>
                  <CxSelect
                    name="accessLevel"
                    label={lang.accessLevel}
                    fullWidth
                    items={accessLevelList}
                    defaultValue={accessLevel}
                    onChange={handleAccessLevelChange}
                  />
                </div>
              ) : null}

              <div className="cx-admin-footer-actions">
                <button type="button" className="cx-button cx-button-cancel" tabIndex="-1" onClick={onCancelClick}>
                  {lang.cancel}
                </button>
                <button type="submit" className="cx-button cx-button-save">
                  {lang.share}
                </button>
              </div>
              {error == null ? null : <Alert type={'validationError'} title={lang.validationError} message={error} />}
            </>
          )}
        </form>
      </section>
    </div>
  );
};

Share.propTypes = {
  workspaceId: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  contentId: PropTypes.string.isRequired,
  shareFormEventHandler: PropTypes.func.isRequired,
};

export default Share;
