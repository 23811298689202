import React, { useState } from 'react';
import './Tree.scss';
import PropTypes from 'prop-types';

const TreeItem = ({ item, onItemClick, buildTree, preIcon, lang, selectedId, dragId, onDraggedItemDropped }) => {
  const [expanded, setExpanded] = useState(true);

  const handleItemClick = (e, item) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    onItemClick(item);
  };

  const handleArrowClick = (e, item) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setExpanded(!expanded);
  };

  const buildSharedIcons = (item) => {
    return (
      <>
        {item.sharedWithMe || item.sharedWithOthers ? (
          <>
            {item.sharedWithMe ? (
              <i className="icon-share rotate" title={lang.sharedWithMe} />
            ) : (
              <i className="icon-share" title={lang.sharedWithOthers} />
            )}
          </>
        ) : null}
      </>
    );
  };



  // Handles dropping a dragged note into a notebook  
  const handleDrop = (e, treeItem) => {
    e.preventDefault();
    const dragItem = JSON.parse(e.dataTransfer.getData(dragId));
    e.target.classList.remove('highlight');
    onDraggedItemDropped(dragItem, treeItem);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragEnter = (e) => {
    e.target.classList.add('highlight');
  };

  const handleDragLeave = (e) => {
    e.target.classList.remove('highlight');
  };


  const hasChildren = item.children && item.children.length > 0;
  return (
    <li key={item.id}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      {hasChildren ? (
        <>
          <i
            className={expanded ? 'icon-down-open-mini' : 'icon-right-open-mini'}
            onClick={(e) => handleArrowClick(e, item)}
          />
          <span
            className={`cx-tree-item ${item.isDefault ? 'cx-tree-item-default' : ''} ${selectedId === item.id ? 'cx-tree-selected' : ''} `}
            onClick={(e) => handleItemClick(e, item)}
            onDrop={(e) => handleDrop(e, item)}
            onDragOver={handleDragOver}
          >
            {preIcon ? preIcon : null}
            <span className="cx-tree-item-name">
              {item.name}
              {buildSharedIcons(item)}
            </span>
          </span>
        </>
      ) : (
        <span
          className={`cx-tree-item root ${item.isDefault ? 'cx-tree-item-default' : ''} ${selectedId === item.id ? 'cx-tree-selected' : ''} `}
          onClick={(e) => handleItemClick(e, item)}
          onDrop={(e) => handleDrop(e, item)}
          onDragOver={handleDragOver}

        >
          {preIcon ? preIcon : null}
          <span className="cx-tree-item-name">
            {item.name}
            {buildSharedIcons(item)}
          </span>
        </span>
      )}

      {hasChildren && expanded ? <ul>{item.children.map((c, index) => buildTree(c))}</ul> : null}
    </li>
  );
};

TreeItem.propTypes = {
  item: PropTypes.object.isRequired,
  onItemClick: PropTypes.func.isRequired,
  buildTree: PropTypes.func.isRequired,
  lang: PropTypes.object.isRequired,
  dragId: PropTypes.string.isRequired,
  onDraggedItemDropped: PropTypes.func,
};

export default TreeItem;
