import React, { useEffect, useState } from 'react';
import './BeerEdit.scss';

import useApi from '../../../core/useApi';
import { Breadcrumb, Loader, NoImagePlaceholder, CxSlider } from '../../../core/components';

import { withRouter } from 'react-router-dom';

// State
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import Dropzone from 'react-dropzone';

// Mui

import { useSnackbar } from 'notistack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import { BeerStyleSearchRequest } from './BeerStyleSearch.dto';
import { BrewerySearchRequest } from './BrewerySearch.dto';

const filter = createFilterOptions();

const BeerEdit = ({ history, match }) => {
  const { get, post, postWithUpload, putWithUpload, del, upload, parseQuery } = useApi();
  const lang = useSelector((state) => state.app.lang);
  const [isLoading, setIsLoading] = useState(false);
  const [beerId] = useState(match.params.id);
  const { enqueueSnackbar } = useSnackbar();

  // Forms
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    defaultValues: {
      id: '',
      name: '',
      drinkPlace: '',
      alcoholPerVolume: '',
      tastingNote: '',
    },
  });

  const breadcrumb = [
    { name: lang.dashboard, path: '/dashboard' },
    { name: lang.beers, path: '/beers' },
    { name: lang.edit, path: null },
  ];

  // Brewery
  const [breweries, setBreweries] = useState([]);
  const [brewery, setBrewery] = React.useState(null);
  const [openBreweryDialog, toggleOpenBreweryDialog] = React.useState(false);

  const getBreweries = async () => {
    const request = new BrewerySearchRequest();
    const response = await get(`breweries${parseQuery(request)}`);
    setBreweries(response);
  };

  const handleBreweryDialogClose = () => {
    setBreweryDialogValue({
      name: '',
      id: null,
    });

    toggleOpenBreweryDialog(false);
  };

  const [breweryDialogValue, setBreweryDialogValue] = React.useState({
    name: '',
    id: '',
  });

  const handleBrewerySubmit = async (event) => {
    event.preventDefault();

    const newBrewery = {
      name: breweryDialogValue.name,
    };

    const response = await post('breweries', newBrewery);
    setBrewery(response);
    handleBreweryDialogClose();
  };

  // Beer Style
  const [styles, setStyles] = useState([]);
  const [style, setStyle] = React.useState(null);
  const [openStyleDialog, toggleOpenStyleDialog] = React.useState(false);

  const getBeerStyles = async () => {
    const request = new BeerStyleSearchRequest();
    const response = await get(`beer-styles${parseQuery(request)}`);
    setStyles(response);
  };

  const handleStyleDialogClose = () => {
    setStyleDialogValue({
      name: '',
      id: null,
    });

    toggleOpenStyleDialog(false);
  };

  const [styleDialogValue, setStyleDialogValue] = React.useState({
    name: '',
    id: '',
  });

  const handleStyleSubmit = async (event) => {
    event.preventDefault();

    const newStyle = {
      name: styleDialogValue.name,
    };

    const response = await post('beer-styles', newStyle);
    setStyle(response);
    handleStyleDialogClose();
  };

  // Rating
  const [rating, setRating] = useState(1);
  const handleBeerRatingValueChange = (value) => {
    setRating(value);
  };
  const getSliderValueText = (value) => {
    return `${value}`;
  };

  const getBeer = async () => {
    const response = await get(`beers/${beerId}`);

    reset({
      id: response.id,
      name: response.name,
      drinkPlace: response.drinkPlace ?? '',
      alcoholPerVolume: response.alcoholPerVolume ?? '',
      tastingNote: response.tastingNote ?? '',
    });

    setImg(response.imageUrl);

    setRating(response.rating);

    if (response.breweryId) {
      setBrewery({ id: response.breweryId, name: response.brewery });
    }

    if (response.styleId) {
      setStyle({ id: response.styleId, name: response.style });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    try {
      getBeerStyles();
      getBreweries();

      if (breweries && styles && beerId !== 'new') {
        getBeer();
      }
    } finally {
      setIsLoading(false);
    }
  }, [beerId]);

  const onCancelClick = () => {
    history.push('/beers');
  };

  // Delete Beer
  const [openDeleteDialog, toggleOpenDeleteDialog] = React.useState(false);

  const handleDeleteDialogClose = () => {
    toggleOpenDeleteDialog(false);
  };

  const onDeleteConfirmClick = () => {
    toggleOpenDeleteDialog(true);
  };
  const onDeleteClick = async () => {
    await del(`beers/${beerId}`);
    toggleOpenDeleteDialog(false);
    enqueueSnackbar('Beer deleted!', {
      variant: 'success',
    });
    history.push('/beers');
  };

  // Save
  const onSaveClick = async (data) => {
    console.log('Data:', data);

    const entity = new FormData();

    if (tempImgData) {
      entity.append('files', tempImgData);
    }
    entity.append('name', data.name);
    entity.append('drinkPlace', data.drinkPlace);
    entity.append('alcoholPerVolume', data.alcoholPerVolume);
    entity.append('tastingNote', data.tastingNote);
    entity.append('rating', rating);

    if (brewery && brewery.id) entity.append('breweryId', brewery.id);

    if (style && style.id) entity.append('styleId', style.id);

    const isNew = data.id === '';
    if (isNew) {
      await postWithUpload('beers', entity);
      enqueueSnackbar(lang.beerAddedToCooler, {
        variant: 'success',
      });
      history.push('/beers');
    } else {
      await putWithUpload(`beers/${data.id}`, entity);
      enqueueSnackbar(lang.beerUpdated, {
        variant: 'success',
      });
    }
  };

  // Image Upload
  const [tempImgData, setImgData] = useState();
  const [tempImg, setTempImg] = useState();
  const [img, setImg] = useState();
  const myUploader = async (files) => {
    setImgData(files[0]);

    // Temp Upload
    const data = new FormData();
    data.append('file', files[0]);
    const response = await upload(`core/temp-image-upload?width=350&height=350`, data);
    setTempImg(response);
  };

  return (
    <>
      <Breadcrumb items={breadcrumb} />
      <div className="cx-beers-edit">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div>
              <form className="cx-beers-edit-form" onSubmit={handleSubmit(onSaveClick)}>
                <input type="hidden" {...register('id')} />

                <div className="cx-beers-edit-img">
                  {tempImg ? (
                    <img src={`data:image/jpg;charset=utf-8;base64,${tempImg}`} alt="beer" />
                  ) : img ? (
                    <img src={`${img}&s=450`} alt="beer" />
                  ) : null}
                  <div className="upload-actions">
                    <Dropzone onDrop={myUploader}>
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps({ multiple: false })}>
                            <input {...getInputProps()} />
                            {img || tempImg ? null : <NoImagePlaceholder multi={false} />}
                            <p>{lang.dropBeerPhoto}</p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                </div>

                <div className="cx-beers-edit-fields">
                  <div>
                    <CxSlider
                      min={0.1}
                      max={5}
                      step={0.1}
                      defaultValue={rating}
                      onSliderValueChanged={handleBeerRatingValueChange}
                      valueText={getSliderValueText}
                      labelText={lang.rating}
                    />
                  </div>

                  <div>
                    <Controller
                      name="name"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: { value: true, message: lang.validationFieldRequired },
                        maxLength: { value: 128, message: 'No more than 128 chars please.' },
                      }}
                      render={({ field }) => <TextField {...field} label={lang.name} variant="outlined" fullWidth />}
                    />
                    {errors.name && <span className="input-error">{lang.validationFieldRequired}</span>}
                  </div>

                  <div>
                    <Controller
                      name="alcoholPerVolume"
                      control={control}
                      defaultValue="" // Set the default value if necessary
                      rules={{
                        pattern: {
                          value: /^[1-9]\d*(\.\d+)?$/,
                          message: 'Invalid alcohol percentage',
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          id="alcoholPerVolume"
                          name="alcoholPerVolume"
                          type="text"
                          label={lang.alcoholByVolume}
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }}
                          {...field} // Spread the field props to connect with react-hook-form
                          error={!!fieldState.error} // Show error state if validation fails
                          helperText={fieldState.error ? fieldState.error.message : ''} // Display error message if validation fails
                        />
                      )}
                    />
                  </div>

                  <div>
                    <Autocomplete
                      value={brewery}
                      onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                          // timeout to avoid instant validation of the dialog's form.
                          setTimeout(() => {
                            toggleOpenBreweryDialog(true);
                            setBreweryDialogValue({
                              name: newValue,
                              id: null,
                            });
                          });
                        } else if (newValue && newValue.inputValue) {
                          toggleOpenBreweryDialog(true);
                          setBreweryDialogValue({
                            name: newValue.inputValue,
                            id: null,
                          });
                        } else {
                          setBrewery(newValue);
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        if (params.inputValue !== '') {
                          filtered.push({
                            inputValue: params.inputValue,
                            name: `Add "${params.inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      id="breweries"
                      options={breweries}
                      getOptionLabel={(option) => {
                        // e.g value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.name;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      renderGroup={(params) => <li key={params.key}>{params.name}</li>}
                      freeSolo
                      renderInput={(params) => <TextField {...params} label={lang.brewery} variant="outlined" />}
                    />
                  </div>

                  <div>
                    <Autocomplete
                      value={style}
                      onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                          // timeout to avoid instant validation of the dialog's form.
                          setTimeout(() => {
                            toggleOpenStyleDialog(true);
                            setStyleDialogValue({
                              name: newValue,
                              id: null,
                            });
                          });
                        } else if (newValue && newValue.inputValue) {
                          toggleOpenStyleDialog(true);
                          setStyleDialogValue({
                            name: newValue.inputValue,
                            id: null,
                          });
                        } else {
                          setStyle(newValue);
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        if (params.inputValue !== '') {
                          filtered.push({
                            inputValue: params.inputValue,
                            name: `Add "${params.inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      id="styles"
                      options={styles}
                      getOptionLabel={(option) => {
                        // e.g value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.name;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      renderGroup={(params) => <li key={params.key}>{params.name}</li>}
                      freeSolo
                      renderInput={(params) => <TextField {...params} label={lang.beerStyle} variant="outlined" />}
                    />
                  </div>

                  <div>
                    <Controller
                      name="tastingNote"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          id="tastingNote"
                          type="text"
                          label={lang.tastingNote}
                          variant="outlined"
                          multiline
                          maxRows={3}
                          fullWidth
                          {...field} // Spread the field props to connect with react-hook-form
                          error={!!errors.tastingNote} // Show error state if validation fails
                          helperText={errors.tastingNote ? lang.validationFieldRequired : ''} // Display error message if validation fails
                        />
                      )}
                    />
                  </div>

                  <div>
                    <Controller
                      name="drinkPlace"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          id="drinkPlace"
                          name="drinkPlace"
                          type="text"
                          label={lang.drinkPlace}
                          variant="outlined"
                          fullWidth
                          {...field} // Spread the field props to connect with react-hook-form
                          error={!!errors.drinkPlace} // Show error state if validation fails
                          helperText={errors.drinkPlace ? lang.validationFieldRequired : ''} // Display error message if validation fails
                        />
                      )}
                    />
                  </div>

                  <div className="cx-admin-footer-actions">
                    {beerId === 'new' ? null : (
                      <button
                        type="button"
                        className="cx-button cx-button-delete"
                        tabIndex="-1"
                        onClick={onDeleteConfirmClick}
                        style={{ float: 'left' }}
                      >
                        {lang.delete}
                      </button>
                    )}
                    <button type="button" className="cx-button cx-button-cancel" tabIndex="-1" onClick={onCancelClick}>
                      {lang.cancel}
                    </button>
                    <button type="submit" className="cx-button cx-button-save">
                      {lang.save}
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <Dialog open={openBreweryDialog} onClose={handleBreweryDialogClose} aria-labelledby="form-dialog-title">
              <form onSubmit={handleBrewerySubmit}>
                <DialogTitle id="form-dialog-name">{lang.addNewBrewery}</DialogTitle>
                <DialogContent>
                  <DialogContentText>{lang.addNewBreweryContextMessage}</DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="brewery-name"
                    value={breweryDialogValue.name}
                    onChange={(event) => setBreweryDialogValue({ ...breweryDialogValue, name: event.target.value })}
                    label="brewery-name"
                    type="text"
                  />
                </DialogContent>
                <DialogActions>
                  <button type="button" className="cx-button cx-button-cancel" onClick={handleBreweryDialogClose}>
                    {lang.cancel}
                  </button>
                  <button type="submit" className="cx-button cx-button-save">
                    {lang.save}
                  </button>
                </DialogActions>
              </form>
            </Dialog>

            <Dialog open={openStyleDialog} onClose={handleStyleDialogClose} aria-labelledby="form-dialog-title">
              <form onSubmit={handleStyleSubmit}>
                <DialogTitle id="form-dialog-name">{lang.addNewBeerStyle}</DialogTitle>
                <DialogContent>
                  <DialogContentText>{lang.addNewBeerStyleContextMessage}</DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="beer-style-name"
                    value={styleDialogValue.name}
                    onChange={(event) => setStyleDialogValue({ ...styleDialogValue, name: event.target.value })}
                    label="beer-style-name"
                    type="text"
                  />
                </DialogContent>
                <DialogActions>
                  <button type="button" className="cx-button cx-button-cancel" onClick={handleStyleDialogClose}>
                    {lang.cancel}
                  </button>
                  <button type="submit" className="cx-button cx-button-save">
                    {lang.save}
                  </button>
                </DialogActions>
              </form>
            </Dialog>

            <Dialog
              open={openDeleteDialog}
              onClose={handleDeleteDialogClose}
              aria-labelledby="beer-delete-dialog-title"
            >
              <DialogTitle id="beer-delete-dialog-title">{lang.deleteBeerConfirmation}</DialogTitle>
              <DialogActions>
                <button type="button" className="cx-button cx-button-cancel" onClick={handleDeleteDialogClose}>
                  {lang.cancel}
                </button>
                <button type="button" className="cx-button cx-button-delete" onClick={onDeleteClick}>
                  {lang.delete}
                </button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </div>
    </>
  );
};

export default withRouter(BeerEdit);
