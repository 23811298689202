import React, { useEffect, useState } from 'react';
import './todoItemEdit.scss';

import useApi from '../../../core/useApi';

import { useForm } from 'react-hook-form';

// MUI
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useSnackbar } from 'notistack';
import { CxSelect } from '../../../core/components';

const TodoItemEdit = ({ lang, todoId, itemId, editTodoItemFormEventHandler }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { get, put } = useApi();

  const [showCalendar, setShowCalendar] = useState(false);
  const [enableScheduling, setEnableScheduling] = useState(null);
  const [showBetaScheduling] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const getTodoItem = async () => {
    const response = await get(`todo/${todoId}/items/${itemId}`);
    // console.log(x);
    // console.log(x.data.schedule);
    var item = {
      id: response.id,
      statusId: response.statusId,
      title: response.title,
      description: response.description,
      dueDate: response.schedule.dueDate,
      notifyMe: response.schedule.notifyMe,
    };
    reset(item);
    //setDueDate(x.data.schedule.startTime);
  };
  useEffect(() => {
    getTodoItem();
  }, []);

  const onSaveClick = async (data) => {
    // TODO: Finish the scheduling features.
    // Figure how to handle the `dueDate` value coming back from the api

    let todoItemDto = {
      id: itemId,
      statusId: data.statusId,
      title: data.title,
      description: data.description,
      schedule: null,
    };

    if (dueDate !== '') {
      let startTime = new Date();

      if (dueDate === 2) {
        startTime = dateTimeAddDays(startTime, 1);
      }

      if (dueDate === 3) {
        startTime = dateTimeAddDays(startTime, 7);
      }

      if (dueDate === 4) {
        startTime = new Date(data.dueDateDate);
      }

      todoItemDto.schedule = {
        startTime: formatDate(startTime),
        notifyMe: data.notifyMe,
      };
    }

    await put(`todo/${todoId}/items/${todoItemDto.id}`, todoItemDto);
    reset();
    enqueueSnackbar('Todo item saved!', {
      variant: 'success',
    });
    editTodoItemFormEventHandler('saved');
  };

  const dateTimeAddDays = (dateTime, numberOfDays) => {
    return new Date(dateTime.setTime(dateTime.getTime() + numberOfDays * 86400000));
  };

  const formatDate = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear() + '  ' + strTime;
  };

  const onCancelClick = () => {
    reset();
    editTodoItemFormEventHandler('canceled');
  };

  // Due Date
  const dueDateList = [
    { name: '', value: '' },
    { name: lang.today, value: 1 },
    { name: lang.tomorrow, value: 2 },
    { name: lang.nextWeek, value: 3 },
    { name: lang.pickADate, value: 4 },
  ];

  const [dueDate, setDueDate] = React.useState('');

  const handleDueDateChange = (value) => {
    //  console.log(value);
    setDueDate(value);
    setValue('dueDate', value, true);

    if (value !== '') {
      setEnableScheduling(true);
    } else {
      setEnableScheduling(false);
    }

    setShowCalendar(value === 4);
  };

  return (
    <div className="cx-todo-item-edit">
      <section className="form">
        <form onSubmit={handleSubmit(onSaveClick)}>
          <input type="hidden" {...register('id')} />
          <input type="hidden" {...register('statusId')} />
          <div>
            <TextField
              name="title"
              type="text"
              label={lang.title}
              {...register('title', {
                required: { value: true, message: lang.validationFieldRequired },
              })}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
            {errors.title && <span>{lang.validationFieldRequired}</span>}
          </div>

          <div>
            <TextField
              name="description"
              type="text"
              label={lang.description}
              {...register('description')}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
            {errors.description && <span>{lang.validationFieldRequired}</span>}
          </div>

          {showBetaScheduling ? (
            <>
              <div>
                <CxSelect
                  name="dueDate"
                  label={lang.dueDate}
                  fullWidth
                  items={dueDateList}
                  defaultValue={dueDate}
                  onChange={handleDueDateChange}
                />
              </div>

              {showCalendar ? (
                <div>
                  <TextField
                    id="dueDateDate"
                    label={lang.pickADate}
                    type="datetime-local"
                    defaultValue={new Date()}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    {...register('dueDateDate')}
                  />
                </div>
              ) : null}

              {enableScheduling ? (
                <div>
                  <FormControlLabel
                    control={<Switch name="notifyMe" {...register('notifyMe')} />}
                    label={lang.notifyMe}
                  />
                </div>
              ) : null}
            </>
          ) : null}

          <div className="cx-admin-footer-actions">
            <button type="button" className="cx-button cx-button-cancel" tabIndex="-1" onClick={onCancelClick}>
              {lang.cancel}
            </button>
            <button type="submit" className="cx-button cx-button-save">
              {lang.save}
            </button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default TodoItemEdit;
