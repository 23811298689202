import React, { useEffect, useState } from 'react';
import './todoEdit.scss';

import useApi from '../../../core/useApi';

import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';

import TextField from '@mui/material/TextField';
import { CxSelect, Loader } from '../../../core/components';

const TodoEdit = ({ lang, todoId, editTodoFormEventHandler }) => {
  const { get, post, put } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm({
    defaultValues: {
      id: '',
      title: '',
      categoryId: '',
    },
  });

  const getCategories = async () => {
    setIsLoading(true);
    try {
      const response = await get('core/categories');
      setCategories(response);

      if (response) {
        setCategoryId(response[0].id);
        setValue('categoryId', response[0].id, true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getTodo = async () => {
    setIsLoading(true);
    try {
      const response = await get(`todo/${todoId}`);
      reset({
        id: response.id,
        title: response.title,
        categoryId: response.categoryId,
      });
      setCategoryId(response.categoryId);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCategories();

    if (todoId) {
      getTodo();
    }
  }, [todoId]);

  const onSaveClick = async (data) => {
    console.log(data);
    setIsLoading(true);
    try {
      if (data.id === '') {
        await post('todo', data);
      } else {
        await put(`todo/${data.id}`, data);
      }
      reset();
      enqueueSnackbar(`'${data.title}' ${lang.saved}`, {
        variant: 'success',
      });
      editTodoFormEventHandler('saved');
    } finally {
      setIsLoading(false);
    }
  };

  const onCancelClick = () => {
    reset();
    editTodoFormEventHandler('canceled');
  };

  // Category Select
  const categoryList = categories.map(({ id, name }) => ({ name: name, value: id }));

  const handleCategoryChange = (value) => {
    console.log(value);
    setCategoryId(value);
    setValue('categoryId', value, true);
  };

  return (
    <div className="cx-todo-list-edit">
      {isLoading ? (
        <Loader />
      ) : (
        <section className="form">
          <form onSubmit={handleSubmit(onSaveClick)}>
            <input type="hidden" {...register('id')} />
            <div>
              <Controller
                control={control}
                name="title"
                render={({ field }) => (
                  <TextField {...field} id="title" label={lang.title} variant="outlined" fullWidth />
                )}
                rules={{ maxLength: 128 }}
              />
              {errors.title && <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>}
            </div>

            <div>
              <CxSelect
                name="categoryId"
                label={lang.category}
                fullWidth
                items={categoryList}
                defaultValue={categoryId}
                onChange={handleCategoryChange}
              />
            </div>

            <div className="cx-admin-footer-actions">
              <button type="button" className="cx-button cx-button-cancel" tabIndex="-1" onClick={onCancelClick}>
                {lang.cancel}
              </button>
              <button type="submit" className="cx-button cx-button-save">
                {lang.save}
              </button>
            </div>
          </form>
        </section>
      )}
    </div>
  );
};

export default TodoEdit;
