import React, { useRef, useState } from 'react';
import './todoItem.scss';

import useApi from '../../../core/useApi';

import { useSnackbar } from 'notistack';
import { DropDownMenu, Modal, CxConfirm } from '../../../core/components';

import TodoItemEdit from './todoItemEdit';

export const TodoItem = ({ lang, item, todoItemEventHandler }) => {
  const { put, del } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [editing, setEditing] = useState(false);

  const todoItemEdit = useRef();
  const onEditClick = () => {
    setEditing(true);
    todoItemEdit.current.toggleModal(true);
  };

  const editTodoItemFormEventHandler = (action) => {
    switch (action) {
      case 'canceled':
        setEditing(false);
        todoItemEdit.current.toggleModal(false);
        break;
      case 'saved':
        todoItemEventHandler('updated');
        setEditing(false);
        todoItemEdit.current.toggleModal(false);
        break;
      default:
        throw Error('todoItemEventHandler action not implemented;');
    }
  };

  // Todo Item Delete
  const [showTodoItemDeleteConfirm, setShowTodoDeleteConfirm] = useState(false);

  const onDeleteTodoClick = () => {
    setShowTodoDeleteConfirm(true);
  };

  const handleTodoItemDeleteCancel = () => {
    setShowTodoDeleteConfirm(false);
  };

  const handleTodoItemDeleteConfirm = async () => {
    await del(`todo/${item.todoId}/items/${item.id}`);
    todoItemEventHandler('updated');
    enqueueSnackbar('Todo item deleted!', {
      variant: 'success',
    });
  };

  const onItemClick = async () => {
    if (item.statusId === 1) {
      item.statusId = 2;
    } else if (item.statusId === 2) {
      item.statusId = 3;
    } else {
      item.statusId = 1;
    }

    await put(`todo/${item.todoId}/items/${item.id}/status`, item);
    todoItemEventHandler('updated');
  };

  const dropDownMenuOptions = [
    { icon: 'icon-pencil', text: lang.edit, action: onEditClick },
    { icon: 'icon-trash-2', text: lang.delete, action: onDeleteTodoClick },
  ];

  return (
    <>
      <li className="cx-todo-item">
        <div onClick={onItemClick} className={item.statusId === 3 ? 'todo-item-done' : ''}>
          <div className={item.statusId === 1 ? 'todo-item-active' : 'todo-item-inprogress'}>
            {item.statusId === 3 ? <i className="icon-checkmark" /> : null}
          </div>
          <label>
            {item.title}
            <small>{item.description}</small>
          </label>
        </div>
        <div className="cx-todo-item-settings">
          <DropDownMenu id={`${item.id}-todo-items-settings`} items={dropDownMenuOptions} />
        </div>
      </li>

      <CxConfirm
        lang={lang}
        type="delete"
        message={`${lang.deleteConfirmationMessage}"${item.title}"?`}
        show={showTodoItemDeleteConfirm}
        confirmButtonText={lang.delete}
        onConfirmClick={handleTodoItemDeleteConfirm}
        onCancelClick={handleTodoItemDeleteCancel}
      />

      <Modal ref={todoItemEdit} title={lang.todoItemEdit}>
        {editing ? (
          <TodoItemEdit
            editTodoItemFormEventHandler={editTodoItemFormEventHandler}
            lang={lang}
            todoId={item.todoId}
            itemId={item.id}
          />
        ) : null}
      </Modal>
    </>
  );
};
