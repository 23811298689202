import React from 'react';
import './NotebookItem.scss';

import Chip from '@mui/material/Chip';

import { Card } from '../../../../core/components';

const NotebookItem = ({
  lang,
  workingNotebook,
  title,
  items,
  type,
  onNotebookSelected,
  onSetDefault,
  onShareNotebook,
  onEditNotebook,
  onDeleteNotebook,
}) => {
  const notebooks = items.map((x) => (
    <Card
      className={
        workingNotebook && workingNotebook.id === x.id
          ? 'cx-notebook-list-item cx-notebooks-selected'
          : 'cx-notebook-list-item'
      }
      key={x.id}
    >
      <div>
        <h4 onClick={() => onNotebookSelected(x.id)}>{x.name}</h4>
        <div className="cx-notebook-list-item-actions">
          {type === 'owned' ? (
            x.isDefault ? (
              <div className="cx-div-button-like-green">
                <i className="icon-checkmark" />
                <span>{lang.default}</span>
              </div>
            ) : (
              <button className="cx-button cx-button-simple" onClick={() => onSetDefault(x)}>
                <i className="icon-checkmark" /> <span>{lang.setAsDefault}</span>
              </button>
            )
          ) : null}

          <button className="cx-button cx-button-action" onClick={() => onShareNotebook(x)}>
            <i className="icon-share" /> <span>{lang.share}</span>
          </button>
          <button className="cx-button cx-button-edit" onClick={() => onEditNotebook(x)}>
            <i className="icon-pencil" /> <span>{lang.edit}</span>
          </button>
          <button className="cx-button cx-button-delete" onClick={() => onDeleteNotebook(x)}>
            <i className="icon-trash-2" /> <span>{lang.delete}</span>
          </button>
        </div>
      </div>
      {x.contributors.length > 0 ? (
        <div className="cx-notebook-list-item-shared-users">
          <h4>Contributors</h4>
          {x.contributors.map((c) => (
            <Chip
              key={c.id}
              label={c.name}
              color={c.accessLevel === 'Owner' ? 'primary' : 'success'}
              variant="outlined"
            />
          ))}
        </div>
      ) : null}
    </Card>
  ));

  return (
    <section className="cx-notebook-list">
      <h3>{title}</h3>
      {notebooks}
    </section>
  );
};

export default NotebookItem;
