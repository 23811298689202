import React from 'react';
import './NoImagePlaceholder.scss';
import PropTypes from 'prop-types';

const NoImagePlaceholder = ({ multi, fontSize = 120 }) => {
  return (
    <div className="cx-no-image-placeholder">
      <i className={multi ? 'icon-picture-streamline-1' : 'icon-picture-streamline'} style={{ fontSize: fontSize }} />
    </div>
  );
};

NoImagePlaceholder.propTypes = {
  multi: PropTypes.bool.isRequired,
};

export default NoImagePlaceholder;
