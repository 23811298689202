import React from 'react';
import './Alert.scss';
import PropTypes from 'prop-types';

const Alert = ({ type, title, message }) => {
  const color = () => {
    switch (type) {
      case 'validationError':
        return 'w3-orange';
      default:
        return 'w3-red';
    }
  };
  return (
    <div className={'cx-alert w3-panel ' + color()}>
      {title != null ? <h3>{title}</h3> : null}
      {message != null ? <p>{message}</p> : null}
    </div>
  );
};

Alert.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default Alert;
