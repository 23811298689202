import React, { useEffect, useState, useRef } from 'react';
import './todoSearch.scss';

import useApi from '../../../core/useApi';

import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Modal, Breadcrumb, EmptyState, Loader } from '../../../core/components';

import TodoEdit from './todoEdit';
import Todo from './todo';

const TodoSearch = () => {
  const { get } = useApi();
  const lang = useSelector((state) => state.app.lang);
  const [isLoading, setIsLoading] = useState(false);

  const breadcrumb = [
    { name: lang.dashboard, path: '/dashboard' },
    { name: lang.todoCards, path: null },
  ];

  const [items, setItems] = useState([]);
  const [todoId, setTodoId] = useState(null);
  const [hasEditFormBeingLoaded, setHasEditFormBeingLoaded] = useState(false);

  const databind = async () => {
    setIsLoading(true);
    try {
      const response = await get('todo');

      setItems(response);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    databind();
  }, []);

  const modal = useRef();

  const editTodoClick = (todoId) => {
    setTodoId(todoId);
    setHasEditFormBeingLoaded(true);
    modal.current.toggleModal(true);
  };

  const editTodoFormEventHandler = (action) => {
    switch (action) {
      case 'saved':
        databind();
        setTodoId(null);
        modal.current.toggleModal(false);
        setHasEditFormBeingLoaded(null);
        break;
      case 'canceled':
        setTodoId(null);
        modal.current.toggleModal(false);
        setHasEditFormBeingLoaded(null);
        break;
      case 'delete':
        databind();
        setTodoId(null);
        setHasEditFormBeingLoaded(null);
        break;
      default:
        break;
    }
  };

  const list = items.map((item) => (
    <Todo
      key={item.key}
      todo={item}
      lang={lang}
      editTodoClick={editTodoClick}
      editTodoFormEventHandler={editTodoFormEventHandler}
    />
  ));

  const editForm = hasEditFormBeingLoaded ? (
    <TodoEdit lang={lang} todoId={todoId} editTodoFormEventHandler={editTodoFormEventHandler} />
  ) : null;

  return (
    <div className="cx-todo-search">
      <Breadcrumb items={breadcrumb} />
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {list.length === 0 ? (
              <EmptyState
                title={lang.welcomeToTodoCardsTitle}
                description={lang.welcomeToTodoCardsDesc}
                onActionClick={(x) => editTodoClick(null)}
                actionText={lang.newTodoCard}
              />
            ) : (
              <>
                <div className="cx-admin-top-actions">
                  <button className="cx-button cx-button-new" onClick={(x) => editTodoClick(null)}>
                    {lang.newTodoCard}
                  </button>
                </div>

                <div className="cx-todo-list">{list}</div>
              </>
            )}
            <Modal ref={modal} title={lang.todoCard}>
              {editForm}
            </Modal>
          </>
        )}
      </>
    </div>
  );
};

export default withRouter(TodoSearch);
